import React, { useMemo, useState, useCallback, isValidElement, useRef } from 'react'
import cx from 'classnames'
import { useUniqueId } from 'hooks'

import { WidthContainer } from 'components/layout'

import useQueryTabs from './util/useQueryTabs'
import useTabsKeyboard from './util/useTabsKeyboard'

import Tab from './Tab/Tab'
import Content from './Content/Content'
import type { TabsContentProps } from './Content/Content'


type ActiveColor = 'gold-50' | 'white'

type Child = React.ReactElement<TabsContentProps>

export type TabsProps = {
  children: Array<Child>
  className?: string
  tabsClassName?: string // use to override width
  activeTab?: string
  defaultActiveTab?: string
  withWidthContainer?: boolean
  withQuery?: boolean // sync active tab with query parameter
  withScroll?: boolean // scroll to top of page on active tab change
  activeColor?: ActiveColor
  justify?: 'justify-start' | 'justify-around' | 'justify-center' | 'justify-between'
  onChange?: (id: string) => void
}

type TabsComponent = React.FC<TabsProps> & {
  Content: React.CFC<TabsContentProps>
}

const activeColorToClassName: Record<ActiveColor, string> = {
  'gold-50': 'border-gold-50',
  'white': 'border-white',
}

const Tabs: TabsComponent = (props) => {
  const { children, className, tabsClassName = 'min-w-full', activeColor = 'gold-50', justify = 'justify-start', activeTab: controllableActiveTab, defaultActiveTab,
    withWidthContainer = false, withQuery = false, withScroll = true, onChange } = props

  const tabsId = useUniqueId('tabs')

  const [ tabContentById, availableTabIds ] = useMemo(() => {
    const tabContentById = {}
    const availableTabIds = []

    React.Children.toArray(children).forEach((child) => {
      if (!React.isValidElement(child)) {
        return
      }

      const id = child?.props?.id

      if (id) {
        availableTabIds.push(id)
        tabContentById[id] = child
      }
    })

    return [ tabContentById, availableTabIds ]
  }, [ children ])

  const state = useState(defaultActiveTab || availableTabIds[0])
  const queryState = useQueryTabs({ availableTabIds, defaultActiveTab, withScroll })

  let activeTab
  let setActiveTab

  if (controllableActiveTab && typeof onChange === 'function') {
    activeTab = controllableActiveTab
    setActiveTab = onChange
  }
  else {
    [ activeTab, setActiveTab ] = withQuery ? queryState : state
  }

  const tabsRef = useTabsKeyboard({ availableTabIds, activeTab, setActiveTab })

  const activeTabRef = useRef<string>()
  activeTabRef.current = activeTab

  const handleClick = useCallback((id) => {
    if (activeTabRef.current === id) {
      return
    }

    setActiveTab(id)

    if (typeof onChange === 'function') {
      onChange(id)
    }
  }, [ setActiveTab, onChange ])

  const tabs = (
    <WidthContainer className="no-scrollbar overflow-x-auto" ignore={!withWidthContainer}>
      <div
        className={cx(`inline-flex border-b border-solid`, tabsClassName, activeColorToClassName[activeColor], justify)}
        ref={tabsRef}
        role="tablist"
      >
        {
          React.Children.map<React.ReactElement, Child>(children, (child) => {
            if (!isValidElement(child)) {
              return child
            }

            const { id, title, titleContent } = child.props
            const isActive = id === activeTab
            const withMargin = justify === 'justify-start' || justify === 'justify-center'

            return (
              <Tab
                key={id}
                id={id}
                tagId={`${tabsId}-${id}`}
                title={title}
                titleContent={titleContent}
                isActive={isActive}
                activeColor={activeColor}
                withMargin={withMargin}
                onClick={handleClick}
              />
            )
          })
        }
      </div>
    </WidthContainer>
  )

  return (
    <div className={className} id={tabsId}>
      {tabs}
      <div
        id={`${tabsId}-${activeTab}-panel`}
        role="tabpanel"
        tabIndex={0}
        aria-labelledby={`${tabsId}-${activeTab}`}
      >
        {tabContentById[activeTab]}
      </div>
    </div>
  )
}

Tabs.Content = Content


export default Tabs
